import React from "react";
import { Link } from "gatsby";

import SecondaryLanding from "../layouts/secondary-landing";
import SEO from "../components/seo/seo";
import BestBanksDefault from "../components/best-banks/best-banks-default";

const SystemUpgrade = () => {
  const SEOData = {
    title: "System Upgrade",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "System Upgrade"
      },
      {
        name: "description",
        property: "og:description",
        content: "WaFd online banking currently down for system upgrade."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/system-upgrade"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <section className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h4 className="text-success">
              We want to inform you in advance that our online and mobile banking services will be unavailable between
              8pm PST on Friday, March 1 and Monday morning, March 4, to update systems affected by the acquisition of
              Luther Burbank Savings.
            </h4>
            <p>
              During this time, you will not be able to access account information online. We encourage you to plan any
              deposits and online account transactions, like transfers and Bill Payments, in advance as the system
              update will affect the following:
            </p>
            <ul>
              <li>ATM deposits made over the weekend will not be available until Monday, March 4</li>
              <li>
                While our Client Care Center is available 24/7, our team will be unable to provide specific account
                information until Monday, March 4
              </li>
            </ul>
            <p>
              We apologize for any inconvenience this may cause and assure you that our team will be working diligently
              to complete the update as quickly as possible.
            </p>
            <p>Your account and personal information remain secure throughout this process.</p>
            <p>
              If you have questions about the system update, or the Luther Burbank Savings merger, please connect with
              your{" "}
              <Link to="/locations" id="locations-page-link">
                local branch
              </Link>
              , or reach our 24-hour Client Care Center at{" "}
              <a href="tel:800-324-9375" id="wafd-c3-tel-link">
                800-324-9375
              </a>
              .
            </p>
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default SystemUpgrade;
